// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';
import { h2Styles, h3Styles, pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
		position: relative;
		z-index: 2;
		position: relative;
		margin: 6rem 0 0 0;
		padding: ${props.theme.padS} 0;
		overflow: hidden;

		${breakup.medium`
			padding: ${props.theme.padM} 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0;
			margin: ${props.theme.padL} 0 0 0;
		`}
	`
);

export const Title = styled(H1)(
	(props) => css`
		margin: 0 0 3.6rem 0;

		${breakup.medium`
            margin: 0 0 4.8rem 0;
        `}

		${breakup.large`
            margin: 0 0 6rem 0;
        `}
	`
);

export const Content = styled.div(
	(props) => css`
		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		justify-content: flex-start;

		h2 {
			${h3Styles}
		}

		p {
			${pStyles}
			opacity: 0.6;
		}

		a {
			${pStyles}
			display: inline-block;
			transition: ${props.theme.ease};

			&:hover {
				color: ${props.theme.bc1};
			}
		}

		ul {
			li {
				${pStyles}
				opacity: 0.6;
				margin: 0 0 1.2rem;

				&:last-child {
					margin: 0;
				}

				&:before {
					content: '• ';
				}
			}
		}

		> * {
			transition: ${props.theme.ease};
			margin: 0 0 2.4rem 0;

			${breakup.large`
                margin: 0 0 6rem 0;
            `}
		}

		.links {
			display: flex;
			flex-flow: column wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}
	`
);
